<template>
<div>
    <v-container>
        <h1 class="mb-3">会社情報の入力</h1>
        <p>設定を変更後「保存する」をクリックしてください</p>
        <v-card class="px-6 pt-8 pb-6 company_box relative-center">
            <div class="mb-10">予約サイトに「会社概要」として表示されます</div>
            <v-form ref="form">
                <v-row>
                    <v-col cols="12" class="my-0 py-0">
                        <v-text-field v-model="company" :rules="requredRules" label="会社名*" outlined type="text" maxlength="255" />
                    </v-col>
                    <v-col cols="6" class="my-0 py-0">
                        <div @click="dialog=true">
                            <v-text-field :value="founding?moment(founding).format('yyyy年M月D日'):''" label="設立日" outlined type="text" class="zip noevent" />
                        </div>
                    </v-col>
                    <v-col cols="6" class="my-0 py-0">
                        <v-text-field v-model="capital" label="資本金" outlined type="text" class="zip" />
                    </v-col>
                    <v-col cols="6" class="my-0 py-0">
                        <br>
                        <v-text-field v-model="zip" :rules="zipRules" label="郵便番号" outlined type="text" maxlength="8" class="zip" />
                    </v-col>
                    <v-col cols="12" class="my-0 py-0">
                        <v-text-field v-model="address" label="住所*" :rules="requredRules" type="text" outlined maxlength="255" />
                        <br>
                    </v-col>
                    <v-col cols="12" class="my-0 py-0">
                        <v-text-field v-model="ceo" label="代表者名" outlined type="text" maxlength="255" />
                    </v-col>
                    <v-col cols="6" class="my-0 py-0">
                        <v-text-field v-model="tel" label="電話番号" type="text" outlined maxlength="15" class="zip" />
                    </v-col>
                    <v-col cols="6" class="my-0 py-0">
                        <v-text-field v-model="fax" label="ＦＡＸ番号" type="text" outlined maxlength="15" class="zip" />
                    </v-col>
                     <v-col cols="12" class="my-0 py-0">
                        <v-text-field v-model="web" label="ＷＥＢサイト" outlined type="text" maxlength="255" />
                    </v-col>
                    <v-col cols="12" class="my-0 py-0">
                        <v-textarea v-model="job" label="事業内容" type="text" outlined maxlength="1000" />
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
        <v-btn color="#E64A19" x-large dark class="f-12 my-6" @click="submit()">保存する</v-btn>
    </v-container>
    <v-dialog width="300" v-model="dialog">
        <v-card>
            <v-date-picker v-model="founding" locale="jp-ja" :day-format="date => new Date(date).getDate()"></v-date-picker>
            <div class="text-right">
                <v-btn text @click="founding=''" v-show="founding">設立日を削除する</v-btn>
            </div>
        </v-card>
    </v-dialog>
        <Snackbar ref="snack" />
</div>
</template>

<script>
import moment from "moment"
import Vue from "vue"
Vue.prototype.moment = moment

import axios from "axios"
import Snackbar from "@/components/snackbar.vue"

import d from "@/d"
import Zip from '@/mixins/zip'
import Form from '@/mixins/form'
export default {
    mixins: [Zip, Form],
    data() {
        return {
            dialog: false
        }
    },
    components:{
        Snackbar
    },
    computed: {
        Info() {
            return this.$store.getters['user/info']
        },

    },
    async mounted() {
        this.company = this.Info.company
        this.founding = this.Info.founding
        this.capital = this.Info.capital
        this.zip = this.Info.zip
        this.ceo = this.Info.ceo
        this.tel = this.Info.tel
        this.fax = this.Info.fax
        this.web = this.Info.web
        this.job = this.Info.job
        await d.sleep(100)
        this.address = this.Info.address
    },
    methods: {
      async  submit() {
          if (!this.$refs.form.validate()) {
                return false;
            }
            const data = {
                company: this.company,
                founding: this.founding,
                capital: this.capital,
                zip: this.zip,
                address: this.address,
                ceo: this.ceo,
                tel: this.tel,
                fax: this.fax,
                web: this.web,
                job: this.job
            }
            const response = await axios.post("/user/companySave", data)
            if (response.data.ok) {
                await this.$store.dispatch("user/save", response.data);
                this.$refs.snack.snack = true
                this.$refs.snack.message = "保存しました"
            }else{
                alert("エラーが発生しました")
            }
        },
    },
    watch: {
        founding() {
            this.dialog = false
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/user/company.scss";
</style>
